import { useEffect, useRef } from 'react';

const useDocumentTitle = (title, defaultTitle = 'PowerEngage') => {
    const previousTitle = useRef(document.title);

    useEffect(() => {
        document.title = title;
        return () => {
            document.title = defaultTitle;
        };
    }, [title]);

    useEffect(() => {
        previousTitle.current = document.title;
    }, []);

};
export default useDocumentTitle;