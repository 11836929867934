import React, {useContext, useEffect} from 'react';
import getOidcServiceInstance from "./EIDOidcSingletonService";
import {CircularProgress} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import {EidAuthContext} from "./EIDAuthContext";

const SSORedirection = () => {
    const history = useHistory();
    const {setSignInEidUser} = useContext(EidAuthContext);

    useEffect(() => {
        const handleSSORedirection = async () => {
            const oidcInstance = await getOidcServiceInstance();
            let currentUser = await oidcInstance?.getUser();
            if (currentUser) {
                setSignInEidUser(currentUser);
                history.push('/');
            } else {
                console.log('SSO redirection initiated')
                await oidcInstance?.signIn();
            }
        }
        handleSSORedirection();
    }, []);

    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh'}}>
            <CircularProgress size="5rem"/>
        </div>
    );
};

export default SSORedirection;