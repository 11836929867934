import eidConfig from './EIDConfig';

const loadEidSdk = (sdkUrl) => {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = sdkUrl;
        script.type = "module";

        script.onload = () => resolve();
        script.onerror = () => reject(new Error('Failed to load the EID SDK script'));

        document.head.appendChild(script);
    });
};

const setUpEidSdkOidc = async () => {
    let eidSdk = window.eid;
    if (!eidSdk) {
        console.log('EID SDK not available. Loading the script...');
        const sdkUrl = window.__RUNTIME_CONFIG__.EID_AUTHORITY_URL + 'EnterpriseIdentitySdk';
        try {
            await loadEidSdk(sdkUrl);
            eidSdk = window.eid;
            if (!eidSdk) {
                console.log('EID SDK is still not available after script reload');
                return;
            }
        } catch (error) {
            console.error(error.message);
            return;
        }
    }

    await eidSdk.configure((configuration) => {
        configuration.openIdConnectConfiguration = {
            enabled: true,
            authority: eidConfig.oidc.authority,
            clientId: eidConfig.oidc.clientId,
            redirectUri: eidConfig.oidc.redirectUri,
            postLogoutRedirectUri: eidConfig.oidc.postLogoutRedirectUri,
            responseType: eidConfig.oidc.responseType,
            scope: eidConfig.oidc.scope,
            automaticSilentRenew: eidConfig.oidc.automaticSilentRenew
        };
    });

    return eidSdk?.oidc ?? null;
};

export default setUpEidSdkOidc;