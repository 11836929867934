import React from "react"
import { AssetButtonSort, AssetButtonFilter, AssetButtonFilterValueCellClear, AssetButtonFilterValueCellClearAll, AssetButtonNavBack } from "../icons/Assets"
import { Link } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import _ from 'lodash';

function GenericButtonIcon(props){
  const { icon } = props
  switch(icon) {
    case 'none':
      return ( null )
    default:
      return (
        <div className="buttonIcon">{props.icon}</div>
      )
  }
}

function GenericButtonTitle(props){
  const { title } = props
  switch(title) {
    case 'none':
      return ( null )
    default:
      return (
        <div className="buttonTitle">{props.title}</div>
      )
  }
}

function Button(props){
  const { style } = props
    let styleClass;
  switch(style) {
    case 'primary':
        styleClass = "tint";
        break;
    case 'primaryProminent':
        styleClass = "tintProminent";
        break;
    case 'disabled':
    case 'onDark':
    case 'destructive':
    case "dismiss":
        styleClass = style;
        break;
    case 'secondary':
    default:
        styleClass = "action";
        break;
  }
    return (
        <div className={`button ${styleClass} ${ props.size } ${ props.addClass }`} onClick={props.onClick ? props.onClick : undefined}>
            <GenericButtonIcon icon={props.icon} />
            <GenericButtonTitle title={props.title} />
        </div>
    )
}

function SortButton(props){
  const { buttonState } = props
  switch(buttonState) {
    case 'isOpen':
      return (
        // This case should be triggered when the button has been clicked and the sort list is presented. 
        // When the sort list is dismissed (upon selecting a new/existing value or by clicking outside),
        // the state should change to nothing or to isSorted if the new value is not the default sort value.
        <div className={`sortButton isOpen ${ props.addClass }`}>
          <AssetButtonSort />
          <div className="sortButtonTitle">Sort</div>
          <div className="sortButtonValue">{props.sortValue}</div>
        </div>
      )
    case 'isSorted':
      return (
        // In this case, the user has changed the default sort value to something else.
        <div className={`sortButton isSorted ${ props.addClass }`}>
          <AssetButtonSort />
          <div className="sortButtonTitle">Sort</div>
          <div className="sortButtonValue">{props.sortValue}</div>
        </div>
      )
    default:
      return (
        // This is the default case.
        <div className={`sortButton ${ props.addClass }`}>
          <AssetButtonSort />
          <div className="sortButtonTitle">Sort</div>
          <div className="sortButtonValue">{props.sortValue}</div>
        </div>
      )
  }
}

/**
 *
 * @param addClass
 * @param buttonState
 * @param enabledFilters - list of objects with structure {title: 'Readable Filter Name', key: SOME_CONSTANT}
 * @param filterValue
 * @param onFilterClear
 * @returns {JSX.Element}
 * @constructor
 */
function FilterButton({addClass, buttonState, enabledFilters, filterValue, onFilterClear, onClearAll,title}){
  switch(buttonState) {
    case 'isFiltered':
    case 'isOpen':
      return (
        // This case should be triggered when the button has been clicked and the filter list is presented. 
        // When the sort list is dismissed (upon selecting a new/existing value or by clicking outside),
        // the state should change to nothing or to isFiltered if the user selected a sort value.
        <div className={`filterButton ${buttonState} ${ addClass }`}>
          <AssetButtonFilter />
          <div className="filterButtonTitle">Filter</div>
            {
                enabledFilters ? (
                    _.map(enabledFilters, function(ef, k){
                        return (
                            <div className="filterButtonValue" key={k}>
                                <div className="filterButtonValueCell">
                                    <div className="filterButtonValueCellTitle">{ef.title}</div>

                                    <div onClick={onFilterClear ? onFilterClear.bind(this, ef.key) : null} className="filterButtonValueCellClear">
                                        <AssetButtonFilterValueCellClear />
                                    </div>
                                </div>
                            </div>
                        )
                    })
                ) : (
                    <div className="filterButtonValue">
                        <div className="filterButtonValueCell">
                            <div className="filterButtonValueCellTitle">{filterValue}</div>

                            <div onClick={onFilterClear ? onFilterClear : null} className="filterButtonValueCellClear">
                                <AssetButtonFilterValueCellClear />
                            </div>
                        </div>
                    </div>
                )
            }
            {
                onClearAll ? (
                        <div onClick={onClearAll} className="filterButtonValueClearAll">
                            <AssetButtonFilterValueCellClearAll />
                        </div>
                ) : null
            }
        </div>
      )
    default:
      return (
        // This is the default case.
        <div className={`filterButton ${ addClass }`}>
          <AssetButtonFilter />
          <div className="filterButtonTitle">{title ? title : 'Filter'}</div>
        </div>
      )
  }
}

function NavBackButton(props){
  return (
    <Link className="button medium tint backButton" to={props.parentLink}>
      <AssetButtonNavBack />
      <div className="backTitle">{props.parentTitle}</div>
    </Link>
  )
}

//Based on More+ button on https://neogov.jira.com/jira/software/c/projects/EN/issues/?filter=allissues
function FiltersSelectButton(props)
{
  return (<div className="filterButton filtersSelectButton navAction">
    <span className="filterButtonTitle">
      More filters <AddIcon />
    </span>
  </div>);
}

function PaginationExpanderButton(props){
  return (
    <div className="paginationExpander" onClick={props.onClick}>
      <div className="expanderButton">
        <div className="buttonTitle">Show More</div>
        <div className="buttonIcon"/>
      </div>
    </div>
  )
}

export { Button, SortButton, FilterButton, NavBackButton, PaginationExpanderButton, FiltersSelectButton }
