import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import getOidcServiceInstance from "./EIDOidcSingletonService";
import { CircularProgress } from "@material-ui/core";

const EIDPostSignOutCallback = () => {
    const history = useHistory();
    useEffect(() => {
        const handleEidSignOutCallback = async () => {
            const oidcInstance = await getOidcServiceInstance();
            oidcInstance?.processPostSignOutRedirect()
                .then(() => {
                    localStorage.removeItem('eidUser');
                    localStorage.removeItem('eidSignOut');
                    history.push('/auth');
                })
                .catch(error => {
                    console.error('Logout redirect callback error:', error);
                    localStorage.removeItem('eidUser');
                    localStorage.removeItem('eidSignOut');
                    history.push('/auth');
                });
        };

        handleEidSignOutCallback();
    }, []);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress size="5rem" />
        </div>
    );
};

export default EIDPostSignOutCallback;