import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DateRangePicker = ({startDate, endDate, onDateChange}) => {
    return (
        <div className="datePickerWrapper">
            <DatePicker
                selected={startDate}
                onChange={onDateChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                isClearable
                showIcon
                placeholderText="Select a date range."
                dateFormat="yyyy/MM/dd"
            />
        </div>
    );
};

export default DateRangePicker;