import React from 'react';
import {
    Switch
} from 'react-router-dom'
import PrivateRoute from '../PrivateRoute';
import Team from './team/TeamIndex';
import RoleIndex from "./team/RoleIndex";
import DispositionIndex from "./conversation/DispositionIndex";
import TaskTypesIndex from "./task/TaskTypesIndex";
import FollowUpsIndex from "./followups/FollowUpsIndex";
import DepartmentsIndex from "./departments/DepartmentsIndex";
import TaskActionTypesIndex from "./taskactiontype/TaskActionTypesIndex";
import InterpreterSettingsIndex from "./interpreter/InterpreterSettingsIndex";
import FederationIndex from "./federation/FederationIndex";
import PersonnelIndex from "./personnel/PersonnelIndex";
import RecordsConnectorsIndex from "./recordsconnectors/RecordsConnectorsIndex";
import ProviderRotationsIndex from "./providerrotations/ProviderRotationsIndex";
import PersonnelNotificationIndex from "./personnel/PersonnelNotificationIndex";
import ShiftsIndex from "./shifts/ShiftsIndex";
import FieldOptionsIndex from "./field-options/FieldOptionsIndex";
import EncounterConfigurationIndex from "./encounter-configuration/EncounterConfigurationIndex";
import AppIntegrationsIndex from './app-integrations/AppIntegrationsIndex';
import GroupsIndex from "./groups/GroupsIndex";
import EditResponseGroupPage from './conversation/EditResponseGroupPage';
import EmailDigest from './email-digest/EmailDigest';
import CreateDigest from './email-digest/CreateDigest';
import AgencyInfoIndex from "./agency-info/AgencyInfoIndex";
import PublicFeedbackBoard from "./public-feedback/PublicFeedbackBoard";
import CreatePublicFeedbackBoard from "./public-feedback/createPublicFeedbackBoard";

function Routes( props ){
    return (
        <Switch>
            <PrivateRoute exact path={`/settings`} render={() => <Team/>}/>
            <PrivateRoute exact path={`/settings/team`} render={() => <Team/>}/>
            <PrivateRoute exact path={`/settings/agency-info`} render={() => <AgencyInfoIndex/>}/>
            <PrivateRoute exact path={`/settings/personnel`} render={() => <PersonnelIndex/>}/>
            <PrivateRoute exact path={`/settings/personnelnotifications`} render={() => <PersonnelNotificationIndex/>}/>
            <PrivateRoute exact path={`/settings/providerrotations`} render={() => <ProviderRotationsIndex/>}/>
            <PrivateRoute exact path={`/settings/departments`} render={() => <DepartmentsIndex/>}/>
            <PrivateRoute exact path={`/settings/shifts`} render={() => <ShiftsIndex/>}/>
            <PrivateRoute exact path={`/settings/roles`} render={() => <RoleIndex/>}/>
            <PrivateRoute exact path={`/settings/dispositions`} render={() => <DispositionIndex/>}/>
            <PrivateRoute exact path={`/settings/tasktypes`} render={() => <TaskTypesIndex/>}/>
            <PrivateRoute exact path={`/settings/followup`} render={() => <FollowUpsIndex/>}/>
            <PrivateRoute exact path={`/settings/field-options/:fieldName`} render={() => <FieldOptionsIndex/>}/>
            <PrivateRoute exact path={`/settings/taskactiontypes`} render={() => <TaskActionTypesIndex/>}/>
            <PrivateRoute exact path={`/settings/interpreters`} render={() => <InterpreterSettingsIndex/>}/>
            <PrivateRoute exact path={`/settings/record-connectors`} render={() => <RecordsConnectorsIndex/>}/>
            <PrivateRoute exact path={`/settings/encounter-configuration`} render={() => <EncounterConfigurationIndex/>}/>
            <PrivateRoute exact path={`/settings/app-integrations`} render={() => <AppIntegrationsIndex/>}/>
            <PrivateRoute exact path={`/settings/groups`} render={() => <GroupsIndex/>}/>
            <PrivateRoute exact path={`/settings/groups/:id`} render={() => <EditResponseGroupPage />}/>
            <PrivateRoute exact path={`/settings/activity-digest-email`} render={() => <EmailDigest title='Activity Email Digest' type='ACTIVITY'/>} />
            <PrivateRoute exact path={`/settings/digest-email`} render={() => <EmailDigest type='CPSS' title='CPSS Email Digest'/>} />
            <PrivateRoute exact path={`/settings/create-activity-digest-email`} render={() => <CreateDigest type='ACTIVITY'/>} />
            <PrivateRoute exact path={`/settings/create-cpss-digest-email`} render={() => <CreateDigest type='CPSS'/>} />
            <PrivateRoute exact path={`/settings/edit-digest-email/:id`} render={() => <CreateDigest />} />
            <PrivateRoute exact path={`/settings/public-feedback-board`} render={() => <PublicFeedbackBoard title='Public Feedback Board'/>} />
            <PrivateRoute exact path={`/settings/create-public-feedback-board`} render={() => <CreatePublicFeedbackBoard/>}/>
            <PrivateRoute exact path={`/settings/edit-public-feedback-board/:id`} render={() => <CreatePublicFeedbackBoard/>}/>

        </Switch>
    )
}

export default Routes;