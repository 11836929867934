import React from "react";
import { connect } from "react-redux";
import Modal from "../../common/Modal";
import InputCell from "../../common/form/InputCell";
import _ from "lodash";
import { workflowTypes } from "../../utils/utils";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import TeamList from "../settings/team/TeamList";
import { API } from "aws-amplify";
import { toast } from "react-toastify";

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
})

function AddTask({ onAdd, taskTypes, sourceConversation, activeWorkflow, ...props }) {
    const [addTaskOpen, setAddTaskOpen] = React.useState(false);
    const [taskTypeVal, setTaskTypeVal] = React.useState("");
    const [contactPhoneVal, setContactPhoneVal] = React.useState("");
    const [notes, setNotes] = React.useState(null);
    const [dueDate, setDueDate] = React.useState("");
    const [searchFocused, setSearchFocused] = React.useState(false);
    const [searchInput, setSearchInput] = React.useState("");
    const searchRef = React.createRef();
    const notesRef = React.createRef();
    const [search, setSearch] = React.useState("");
    const [page, setPage] = React.useState(1);
    const [size ] = React.useState(50);
    const [sourceEncounter, setSourceEncounter] = React.useState();
    const [personDemographics, setPersonDemographics] = React.useState();
    const [selectedUser, setSelectedUser] = React.useState();
    const [isSubmitButtonDisabled, setSubmitButtonDisabled] = React.useState(false);


    const loadEncounter = () => {
        API.get('Core', '/api/v1/conversation/' + sourceConversation.id + '/encounter')
            .then(response => {
                    setSourceEncounter(response);
                },
                error => {
                    toast("Could not load encounter details: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );
    }

    const loadDemographics = () => {
        API.get('Core', '/api/v1/conversation-records/' + sourceConversation.id + '/person-demographics')
            .then(response => {
                    setRecordsFromDemographicsResponse(response);
                },
                error => {
                    toast("Could not load patient information: " + error.response.data.message, { position: toast.POSITION.TOP_CENTER, type: toast.TYPE.ERROR });
                }
            );
    }

    const setRecordsFromDemographicsResponse = (response) => {
        setPersonDemographics((response ? response : {}));
    }


    React.useEffect(() => {
        if (sourceConversation != null && activeWorkflow) {
            loadEncounter();
            if (activeWorkflow.type === workflowTypes.PATIENT) {
                loadDemographics();
                if (personDemographics != null && personDemographics.primaryContact != null && personDemographics.primaryContact.phone != null) {
                    setContactPhoneVal(personDemographics.primaryContact.phone);
                } else if (sourceConversation.initiator != null) {
                    setContactPhoneVal(sourceConversation.initiator.phone);
                }
            } else if (activeWorkflow.type === workflowTypes.APPLICANT) {
                if (sourceConversation.initiator != null) {
                    setContactPhoneVal(sourceConversation.initiator.phone);
                }
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sourceConversation, activeWorkflow]);

    React.useEffect(() => {
        if (personDemographics != null && personDemographics.primaryContact != null && personDemographics.primaryContact.phone != null) {
            setContactPhoneVal(personDemographics.primaryContact.phone);
        }
    }, [personDemographics]);

    const onPageChange = (event, page) => {
        setPage(page);
    }

    const onSearchFocused = () => {
        setSearchFocused(true);
    }

    const onSearchBlurred = () => {
        setSearchFocused(false);
    }

    const onSearchChange = (e) => {
        setSearchInput(searchRef.current.value);
    }

    const keyPress = (e) => {
        if (e.keyCode === 13) {
            if (searchRef.current.value !== search) {
                setPage(1);
            }
            setSearch(searchRef.current.value);
        }
    }

    const clearSearch = () => {
        setSearch("");
        setSearchInput("");
    }

    const isSelectedUser = (e) => {
        return (!_.isNil(selectedUser) && e.id === selectedUser.id);
    }

    const onUserSelect = (e) => {
        if (isSelectedUser(e)) {
            setSelectedUser(null);
        }
        else {
            setSelectedUser(e);
        }
    }

    const handleAddTaskOpen = () => {
        reset();
        setAddTaskOpen(true);
        setSubmitButtonDisabled(false);
    }

    const handleAddTaskClose = () => {
        setAddTaskOpen(false);
        reset();
    }

    const reset = () => {
        setAddTaskOpen(false);
    }

    const canSubmit = () => taskTypeVal && dueDate && contactPhoneVal && !isSubmitButtonDisabled

    const handleSubmit = () => {
        if (!canSubmit()) return;

        setSubmitButtonDisabled(true)

        let task = {};
        let taskType = _.find(taskTypes, (t) => t.name === taskTypeVal)

        task.type = taskType;
        task.assignee = selectedUser;
        task.contact = {};
        task.contact.phone = contactPhoneVal;
        task.dueDate = dueDate;
        task.notes = notes;
        task.status = "OPEN";

        setAddTaskOpen(false);
        reset();
        onAdd(task);
    }

    const setTaskType = (obj) => {
        if (obj != null) {
            setTaskTypeVal(obj.value);
        }
        let typeIdx = _.findIndex(taskTypes, function (t) {
            return t.name === obj.value;
        })
        if (taskTypes[typeIdx].defaultContactRoles != null && taskTypes[typeIdx].defaultContactRoles.length > 0) {
            if (sourceEncounter != null) {
                let contactIdx = _.findIndex(sourceEncounter.additionalContacts, function (c) {
                    return c.role === taskTypes[typeIdx].defaultContactRoles[0];
                })
                if (contactIdx > -1) {
                    setContactPhoneVal(sourceEncounter.additionalContacts[contactIdx].contact.phone)
                }
            }
        }
        if (taskTypes[typeIdx].defaultDueDateDelayHours != null) {
            let dt = new Date();
            dt.setHours(dt.getHours() + taskTypes[typeIdx].defaultDueDateDelayHours);
            let dds = toDatetimeLocal(dt);
            setDueDate(dds);
        }
    }

    const toDatetimeLocal = (obj) => {
        var date = obj,
            ten = function (i) {
                return (i < 10 ? '0' : '') + i;
            },
            YYYY = date.getFullYear(),
            MM = ten(date.getMonth() + 1),
            DD = ten(date.getDate()),
            HH = ten(date.getHours()),
            II = ten(date.getMinutes());
        return YYYY + '-' + MM + '-' + DD + 'T' +
            HH + ':' + II;
    };

    const onDueDateChange = (obj) => {
        if (obj != null) {
            setDueDate(obj.target.value);
        }
    }

    const onContactPhoneChange = (phone) => {
        setContactPhoneVal(phone);
    }

    const taskTypesOptions = () => {
        var opts = [];
        if (taskTypes != null) {
            _.forEach(taskTypes, function (t) {
                var opt = { value: t.name, label: t.name }
                opts.push(opt);
            })
        }

        return opts;
    }

    const updateNotes = () => {
        setNotes(notesRef.current.value);
    }

    return (
        <div className="">
            <Modal
                button={props.children}
                content={(
                    <div>
                        <div className={"content"}>
                            <div className="tableView">
                                <div className="tableSection sectionName">
                                    <div className="tableCell titledSelectCell">
                                        <div className="title" id="tasktype1" role="combobox" aria-label="Task type: Use arrow key or press enter to activate the menu and select an option">Task Type</div>
                                        <Select className="selectCell" classNamePrefix="selectCell"
                                                onChange={setTaskType.bind(this)}
                                                placeholder="Select..."
                                                value={taskTypeVal !== "" ? { label: taskTypeVal, value: taskTypeVal } : ""}
                                                options={taskTypesOptions()}
                                        />
                                    </div>

                                    {taskTypeVal ? <div className="tableCell inputCell phoneInput">
                                        <div className="title">Phone Number</div>
                                        <PhoneInput
                                            inputClass="tel-input"
                                            country={'us'}
                                            onlyCountries={['us']}
                                            value={contactPhoneVal}
                                            onChange={onContactPhoneChange}
                                        />
                                    </div> : null}

                                    {taskTypeVal ? <InputCell
                                        label="Due Date"
                                        type={"datetime-local"}
                                        onChange={onDueDateChange.bind(this)}
                                        defaultValue={dueDate}
                                        value={dueDate}
                                    /> : null}

                                </div>
                                <div className="tableSection sectionAssignee">
                                    <div className="sectionTitle">Notes:</div>
                                    <textarea ref={notesRef} placeholder={'Notes...'} className="sectionTextArea" onChange={updateNotes}/>
                                </div>


                                <div className="tableSection sectionAssignee">
                                    <div className="sectionTitle" id="assignee1" role="combobox" aria-label="Assignee: Use arrow key or press enter to activate the menu and select an option">Assignee</div>
                                    <div className={`searchInput tableSearch ${searchFocused ? "isFocused" : ""} ${(searchInput.length > 0) ? "hasInput" : ""}`}>
                                        <div className="icon" />
                                        <input value={searchInput} onChange={onSearchChange} placeholder={"Search Assignees"} onFocus={onSearchFocused} onBlur={onSearchBlurred} className="input" onKeyDown={keyPress} type="text" ref={searchRef} />
                                        <div className="clear" onClick={clearSearch}></div>
                                    </div>

                                    <TeamList
                                        page={page}
                                        size={size}
                                        search={search}
                                        handlePageChange={onPageChange}
                                        selectedUser={[selectedUser]}
                                        onSelect={onUserSelect}
                                        multipleSelect={false}
                                        withoutPhone={true}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="actionBar">
                            <div className="actions">
                                <div onClick={handleAddTaskClose} className="button dismiss medium">
                                    <div className="title" role="button">Cancel</div>
                                </div>

                                <div onClick={canSubmit() ? handleSubmit : null} className={`button confirm medium ${canSubmit() ? '' : 'disabled'}`}>
                                    <div className="title" role="button">Submit</div>
                                </div>
                            </div>

                            <div className="separator"></div>
                        </div>
                    </div>
                )}
                title={"Add Task"}
                size="medium"
                handleClose={handleAddTaskClose}
                handleOpen={handleAddTaskOpen}
                open={addTaskOpen}
                dialogClassName="modalDialog addTask"
            />
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(AddTask);
