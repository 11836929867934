import React, {useEffect, useState} from 'react';
import DateRangePicker from '../../common/DateRangePicker';
import {API} from "aws-amplify";
import {toast} from "react-toastify";

const AddEditSatisfactionFilters = ({
                                        onSaveFilters, onCancel, onResetFilter, shifts, filters,
                                        lastDaysInput, startDate, endDate, shiftId
                                    }) => {
    const [reset, setReset] = useState(false);
    const [shift, setShift] = useState('Select');
    const [role, setRole] = useState({
        callTaker: false,
        investigator: false,
        responder: false,
    });
    const [value, setValue] = useState({
        startDate: null,
        endDate: null,
    });
    const [email, setEmail] = useState(false);
    const [dateOption, setDateOption] = useState('');
    const [lastDays, setLastDays] = useState('');
    const [originalShift, setOriginalShift] = useState('Select');
    const [originalRole, setOriginalRole] = useState({
        callTaker: false,
        investigator: false,
        responder: false,
    });
    const [originalEmail, setOriginalEmail] = useState(false);
    const [originalDateOption, setOriginalDateOption] = useState('');
    const [originalLastDays, setOriginalLastDays] = useState('');
    const [originalDateRange, setOriginalDateRange] = useState({
        startDate: null,
        endDate: null,
    });

    const fetchFilterSettings = async () => {
        try {
            const response = await API.get('Core', '/api/v1/satisfaction/filter');
            const {filters, lastDays, startDate, endDate, shiftId} = response;
            const initialRole = {
                callTaker: filters.includes("CALLTAKER"),
                investigator: filters.includes("INVESTIGATOR"),
                responder: filters.includes("RESPONDER"),
            };
            const initialEmail = filters.includes("HAS_EMAIL");
            const computedDateOption = lastDays ? 'lastInput' : (startDate && endDate) ? 'customRange' : '';
            const computedLastDays = lastDays || '';
            const initialDates = {
                startDate: startDate ? new Date(startDate) : null,
                endDate: endDate ? new Date(endDate) : null,
            };

            setRole(initialRole);
            setOriginalRole(initialRole);

            const fetchedShift = shifts.find(s => s.id === shiftId)?.name || 'Select';
            setShift(fetchedShift);
            setOriginalShift(fetchedShift);

            setEmail(initialEmail);
            setOriginalEmail(initialEmail);

            setDateOption(computedDateOption);
            setOriginalDateOption(computedDateOption);

            setLastDays(computedLastDays);
            setOriginalLastDays(computedLastDays);

            setValue(initialDates);
            setOriginalDateRange(initialDates);
        } catch (error) {
            toast.error(`Failed to fetch filter settings: ${error.response?.data?.message || error.message}`, {
                position: toast.POSITION.TOP_CENTER,
            });
        }
    };

    useEffect(() => {
        fetchFilterSettings();
    }, []);

    const handleResetShift = () => setShift(originalShift);
    const handleResetRole = () => setRole(originalRole);
    const handleResetEmail = () => setEmail(originalEmail);
    const handleResetDate = () => {
        setDateOption(originalDateOption);
        setValue(originalDateRange);
        setLastDays(originalLastDays);
    };

    const handleResetAllFilters = () => {
        setReset(true);
        setShift('Select');
        setRole({
            callTaker: false,
            investigator: false,
            responder: false,
        });
        setEmail(false);
        setDateOption('');
        setLastDays('');
        setValue({startDate: null, endDate: null});
        onResetFilter();
    };

    const validateFilters = () => {
        if (lastDays && (!/^[1-9]\d*$/.test(lastDays))) {
            toast.error("Last Days must be a valid integer.", {
                position: toast.POSITION.TOP_CENTER
            });
            return false;
        }
        if (!dateOption) {
            toast.error("Please select a date option.", {
                position: toast.POSITION.TOP_CENTER
            });
            return false;
        }
        return true;
    };

    const handleApplyFilters = () => {
        if (!validateFilters()) return;
        const filters = [];
        if (role.callTaker) filters.push("CALLTAKER");
        if (role.investigator) filters.push("INVESTIGATOR");
        if (role.responder) filters.push("RESPONDER");
        if (email) filters.push("HAS_EMAIL");
        const prepareLocalDateString = date => {
            if (!date) return null;
            const localDate = new Date(date);
            localDate.setMinutes(localDate.getMinutes() - localDate.getTimezoneOffset());
            return localDate.toISOString().split('T')[0];
        };
        const requestBody = {
            filters: filters.join(','),
            lastDays: dateOption === 'customRange' ? null : (lastDays ? +lastDays : null),
            startDate: dateOption === 'lastInput' ? null : prepareLocalDateString(value.startDate),
            endDate: dateOption === 'lastInput' ? null : prepareLocalDateString(value.endDate),
            shiftId: shifts.find(s => s.name === shift)?.id || null
        };
        API.put("Core", "/api/v1/satisfaction/filter", {body: requestBody})
            .then(response => {
                toast.success("Filters applied successfully!", {
                    position: toast.POSITION.TOP_CENTER
                });
                onSaveFilters();
            })
            .catch(error => {
                toast.error("Could not apply filters: " + error.response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            });
    };

    const hasFilterChanged = () => {
        if (reset) return false;
        const getComparableDate = (date) => (date ? date.toISOString().split('T')[0] : '');
        const hasShiftChanged = shift !== (shifts.find(s => s.id === shiftId)?.name || 'Select');
        const hasRoleChanged =
            role.callTaker !== filters.includes("CALLTAKER") ||
            role.investigator !== filters.includes("INVESTIGATOR") ||
            role.responder !== filters.includes("RESPONDER");

        const hasEmailChanged = email !== filters.includes("HAS_EMAIL");

        const hasLastDaysChanged = lastDays !== (lastDaysInput || '');

        const hasDateChanged = (
            getComparableDate(value.startDate) !== startDate ||
            getComparableDate(value.endDate) !== endDate
        );

        return (
            hasShiftChanged ||
            hasRoleChanged ||
            hasEmailChanged ||
            hasLastDaysChanged ||
            hasDateChanged
        );
    };

    const isAnyFilterSelected = () => {
        return (
            shift !== 'Select' ||
            role.callTaker ||
            role.investigator ||
            role.responder ||
            email ||
            lastDays !== '' ||
            (value.startDate !== null && value.endDate !== null)
        );
    };
    return (
        <div style={{padding: '20px'}}>
            <div style={{marginBottom: '20px'}}>
                <div className="filterAlignView">
                    <label className="inputText">SHIFT</label>
                    <button
                        className="button dismiss small reset"
                        onClick={handleResetShift}
                        disabled={shift === originalShift}
                    >
                        Reset
                    </button>
                </div>
                <div className="inputText">
                    <select
                        value={shift}
                        onChange={(e) => setShift(e.target.value)}
                        style={{width: '100%', height: '30px', marginTop: '10px', color: 'var(--colorTitle)'}}
                        className="tableCell titledSelectCell"
                    >
                        <option className="inputText tableCell titledSelectCell" value="Select">Select</option>
                        {shifts.map(({id, name}) => (
                            <option key={id} value={name} className="inputText tableCell titledSelectCell">
                                {name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="separator" style={{borderTop: '1px solid #ccc', margin: '10px 0'}}></div>
            <div style={{marginBottom: '20px'}}>
                <div className="filterAlignView">
                    <label className="inputText">ROLE</label>
                    <button
                        className="button dismiss small reset"
                        onClick={handleResetRole}
                        disabled={JSON.stringify(role) === JSON.stringify(originalRole)}
                        style={{marginLeft: '10px'}}
                    >
                        Reset
                    </button>
                </div>
                <div style={{marginTop: '10px'}} className="inputText">
                    <input
                        type="checkbox"
                        checked={role.callTaker}
                        onChange={() => setRole({...role, callTaker: !role.callTaker})}
                    /> Call Takers
                </div>
                <div className="inputText">
                    <input
                        type="checkbox"
                        checked={role.investigator}
                        onChange={() => setRole({...role, investigator: !role.investigator})}
                    /> Investigators
                </div>
                <div className="inputText">
                    <input
                        type="checkbox"
                        checked={role.responder}
                        onChange={() => setRole({...role, responder: !role.responder})}
                    /> Responders
                </div>
            </div>
            <div className="separator" style={{borderTop: '1px solid #ccc', margin: '10px 0'}}></div>
            <div style={{marginBottom: '20px'}}>
                <div className="filterAlignView">
                    <label className="inputText">EMAIL</label>
                    <button
                        className="button dismiss small reset"
                        onClick={handleResetEmail}
                        disabled={email === originalEmail}
                        style={{marginLeft: '10px'}}
                    >
                        Reset
                    </button>
                </div>
                <div style={{marginTop: '10px'}} className="inputText">
                    <input
                        type="checkbox"
                        checked={email}
                        onChange={() => setEmail(!email)}
                    /> Has Email
                </div>
            </div>
            <div className="separator" style={{borderTop: '1px solid #ccc', margin: '10px 0'}}></div>
            <div style={{marginBottom: '20px'}}>
                <div className="filterAlignView">
                    <label className="inputText">DATE</label>
                    <button
                        className="button dismiss small reset"
                        onClick={handleResetDate}
                        disabled={
                            dateOption === originalDateOption &&
                            lastDays === originalLastDays &&
                            JSON.stringify(value) === JSON.stringify(originalDateRange)
                        }
                        style={{marginLeft: '10px'}}
                    >
                        Reset
                    </button>
                </div>
                <div style={{marginTop: '10px'}} className="inputText">
                    <input
                        type="radio"
                        checked={dateOption === 'lastInput'}
                        onChange={() => setDateOption('lastInput')}
                    /> Last <input
                    type="text"
                    className="lastDaysInput"
                    value={dateOption === 'lastInput' ? lastDays : ''}
                    onChange={(e) => setLastDays(e.target.value)}
                    disabled={dateOption !== 'lastInput'}
                /> Days
                </div>
                <div className="inputText">
                    <input
                        type="radio"
                        checked={dateOption === 'customRange'}
                        onChange={() => setDateOption('customRange')}
                    />
                    Custom Range
                </div>
                {dateOption === 'customRange' && (
                    <DateRangePicker
                        startDate={value.startDate}
                        endDate={value.endDate}
                        onDateChange={(dates) => {
                            const [start, end] = dates;
                            setValue({startDate: start, endDate: end});
                        }}
                    />
                )}
            </div>
            <div className="filterButtonView">
                <button
                    onClick={handleApplyFilters}
                    className={hasFilterChanged() ? "button confirm small inputText" : "button small disabled dismiss inputText"}
                    disabled={!hasFilterChanged()}>
                    Apply
                </button>
                <button style={{marginRight: '35px'}} onClick={onCancel}
                        className="button dismiss small inputText">
                    Cancel
                </button>
                <button style={{marginLeft: '5px'}} onClick={handleResetAllFilters}
                        className={isAnyFilterSelected() ? "button tint small inputText" : "button small disabled dismiss inputText"}
                        disabled={!isAnyFilterSelected()}>
                    Reset All Filters
                </button>
            </div>
        </div>
    );
};

export default AddEditSatisfactionFilters;