import React, {useState} from 'react'
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {API} from "aws-amplify";
import {toast} from "react-toastify";
import Popup from "reactjs-popup";
import _ from "lodash";
import Modal from "@/common/Modal";
import moment from '@/common/momentConfig';
import {SortButton, FilterButton, Button, PaginationExpanderButton} from "@/components/Buttons"
import {AssetButtonSettings} from "@/icons/Assets"
import {fetchDigestSettings} from "@/shared/satisfaction/actions";

import SatisfactionPersonnelCell from "./SatisfactionPersonnelCell"
import AddEditDigestSettings from "./AddEditDigestSettings";
import AddEditSatisfactionFilters from "./AddEditSatisfactionFilters";

const mapStateToProps = state => ({
    ...state
})

const mapDispatchToProps = dispatch => ({
    getDigestSettings: () => dispatch(fetchDigestSettings())
})

function SatisfactionWithFilterIndex(props) {
    const DATE_FORMAT = "YYYY-MM-DD";
    const DEFAULT_SORT = "NAME ASC";
    let startDateMoment = moment().utc().subtract(30, 'day');
    let endDateMoment = moment().utc();
    let defaultStartDateFormatted = startDateMoment.format(DATE_FORMAT);
    let defaultEndDateFormatted = endDateMoment.format(DATE_FORMAT);
    const [personnel, setPersonnel] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [sortDirection, setSortDirection] = React.useState("ASC");
    const [hasMore, setHasMore] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [filterOptions, setFilterOptions] = React.useState(false);
    const [sort, setSort] = React.useState(DEFAULT_SORT);
    const [filters, setFilters] = React.useState([]);
    const [startDate, setStartDate] = React.useState(defaultStartDateFormatted);
    const [endDate, setEndDate] = React.useState(defaultEndDateFormatted);
    const [startDateInput, setStartDateInput] = React.useState("");
    const [endDateInput, setEndDateInput] = React.useState("");
    const [search, setSearch] = React.useState("");
    const [searchFocused, setSearchFocused] = React.useState(false);
    const [searchInput, setSearchInput] = React.useState("");
    const [shiftNameFilter, setShiftNameFilter] = React.useState("");
    const [shiftId, setShiftId] = React.useState("");
    const [lastDays, setLastDays] = React.useState("");
    const [filtersCount, setFiltersCount] = useState(0);
    const [shifts, setShifts] = useState([]);
    const [loading, setLoading] = useState(true);
    const sortRef = React.useRef();
    const filterRef = React.useRef();
    const calendarRef = React.useRef();
    const searchRef = React.createRef();
    const shiftNameFilterRef = React.createRef();
    const [reload, setReload] = React.useState(true);
    const SORTS = [
        {
            key: "NAME ASC",
            title: "Name, A-Z"
        },
        {
            key: "NAME DESC",
            title: "Name, Z-A"
        },
        {
            key: "CPSS ASC",
            title: "CPSS, Ascending"
        },
        {
            key: "CPSS DESC",
            title: "CPSS, Descending"
        }
    ];

    React.useEffect(() => {
        props.getDigestSettings();
        loadShiftsAndFilters();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload])

    React.useEffect(() => {
        if (!loading) {
            loadPage(page);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    React.useEffect(() => {
        if (!loading) {
            loadPage(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, startDate, endDate, shiftNameFilter, search, sort, loading]);

    const loadShiftsAndFilters = async () => {
        try {
            const [shiftsResponse, filterSettingsResponse] = await Promise.all([
                fetchShifts(),
                fetchInitialFilterSettings()
            ]);
            const {filters, lastDays, startDate, endDate, shiftId} = filterSettingsResponse;
            let filterCount = 0;
            if (filters.includes("HAS_EMAIL")) filterCount++;
            if (filters.includes("CALLTAKER") || filters.includes("INVESTIGATOR") || filters.includes("RESPONDER")) filterCount++;
            setFilters(filters ? (filters.split(',')) : []);
            setLastDays(lastDays);
            if (lastDays) {
                setStartDate(moment().utc().subtract(lastDays, 'day').format(DATE_FORMAT));
                setEndDate(moment().utc().format(DATE_FORMAT));
                filterCount++;
            } else {
                setStartDateInput(startDate ? (filterCount++, startDate) : "");
                setEndDateInput(endDate ? endDate : "");
                setStartDate(startDate ? startDate : defaultStartDateFormatted);
                setEndDate(endDate ? endDate : defaultEndDateFormatted);
            }
            const matchingShift = shiftsResponse.find(shift => shift.id === shiftId);
            if (matchingShift) {
                setShiftNameFilter(matchingShift.name);
                setShiftId(matchingShift.id);
                filterCount++;
            }
            setFiltersCount(filterCount);
            setLoading(false);
        } catch (error) {
            console.error("Error in initializing component data:", error);
            setLoading(false);
        }
    };

    const fetchShifts = async () => {
        try {
            const response = await API.get('Core', '/api/v1/shift');
            setShifts(response);
            return response;
        } catch (error) {
            toast.error(`Could not get shifts: ${error.response?.data?.message || error.message}`, {
                position: toast.POSITION.TOP_CENTER,
            });
        }
    };

    const fetchInitialFilterSettings = async () => {
        try {
            const response = await API.get('Core', '/api/v1/satisfaction/filter');
            return response;
        } catch (error) {
            toast.error(`Failed to fetch filter  settings: ${error.response?.data?.message || error.message}`, {
                position: toast.POSITION.TOP_CENTER,
            });
        }
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleFilterOptionsOpen = () => {
        setFilterOptions(true);
    };

    const handleFilterOptionsClose = () => {
        setFilterOptions(false);
    };

    const handleSaveFilters = () => {
        handleFilterOptionsClose();
        reloadData();
    };

    const handleClearFilters = () => {
        clearAllFilters(false);
    };

    const reloadData = () => {
        setReload(prev => !prev);
    };

    const clearAllFilters = (reset) => {
        const requestBody = {
            filters: "",
            lastDays: null,
            startDate: null,
            endDate: null,
            shiftId: null
        };
        API.put("Core", "/api/v1/satisfaction/filter", {body: requestBody})
            .then(response => {
                setFilters([]);
                setShiftId("");
                setLastDays("");
                setStartDate(defaultStartDateFormatted);
                setEndDate(defaultEndDateFormatted);
                setFiltersCount(0);
                if (reset) {
                    handleFilterOptionsClose();
                }
                toast.success("All filters reset successfully!", {
                    position: toast.POSITION.TOP_CENTER
                });
            })
            .catch(error => {
                toast.error("Could not reset filters: " + error.response.data.message, {
                    position: toast.POSITION.TOP_CENTER
                });
            });
    }
    const toggleFilter = (filter) => {
        if (_.includes(filters, filter)) {
            _.remove(filters, function (remFilter) {
                return remFilter === filter;
            });
            setFilters(Object.assign([], filters));
        } else {
            filters.push(filter);
            setFilters(Object.assign([], filters));
        }
        filterRef.current.close();
    }

    const onSortClick = (chosenSort) => {
        // Selecting a sort - default to desc
        setSort(chosenSort);  // Directly set sort to the chosen key
        sortRef.current.close();
    }
    const handleAddSuccess = (response, close) => {
        props.getDigestSettings();
        if (close) {
            handleClose();
        }
    }

    const loadMore = () => {
        setPage(page + 1);
    }

    const keyPress = (e) => {
        if (e.keyCode === 13) {
            setSearch(searchRef.current.value);
        }
    }

    const keyPressShiftName = (e) => {
        if (e.keyCode === 13) {
            setShiftNameFilter(shiftNameFilterRef.current.value);
        }
    }

    const loadPage = (pageNum) => {
        const [sortKey, direction] = sort.split(" ");
        API.get("Core", "/api/v1/survey-reports/cpss", {
            queryStringParameters: {
                page: pageNum,
                sort: sortKey,
                sortDirection: direction,
                startDate: startDate,
                endDate: endDate,
                search: search,
                shiftName: shiftNameFilter,
                filters: filters
            }
        }).then(response => {
            if (pageNum === 0) {
                setPersonnel(response.content);
            } else {
                setPersonnel(personnel.concat(response.content));
            }
            setPage(response.number);
            setHasMore(!response.last);
        }, err => {
            toast("Could not load satisfaction details: " + err.response.data.message, {
                position: toast.POSITION.TOP_CENTER,
                type: toast.TYPE.ERROR
            });
        })
    }

    let selectedSort = _.find(SORTS, function (s) {
        return s.key === sort;
    });
    return (
        <div className="rootView satisfactionView">
            <div className="navBar">
                <div className="navTitle">
                    <div className="viewName">Personnel Satisfaction</div>
                </div>
                <div className="navActions">
                    <Modal
                        button={(
                            <Button
                                title="none"
                                icon={<AssetButtonSettings/>}
                                size="medium"
                                addClass="settingsButton navAction"
                            />
                        )}
                        content={(<AddEditDigestSettings
                            existingDigestSettings={props.satisfaction.digestSettings}
                            onSuccess={handleAddSuccess}
                        />)}
                        dialogClassName="modalDialog satisfactionDigestPreferences"
                        title="Personnel Email Digest"
                        size="medium"
                        handleClose={handleClose}
                        handleOpen={handleOpen}
                        open={open}
                    />
                </div>

                <div className="separator"></div>
            </div>
            <div className="satisfactionAlignMainView">
                <div className="satisfactionComponentsAlignView">
                    <div
                        className={`searchInput action ${searchFocused ? "isFocused" : ""} ${(searchInput.length > 0) ? "hasInput" : ""}`}
                        style={{marginRight: 10}}>
                        <div className="icon"/>
                        <input value={searchInput} onChange={(e) => setSearchInput(e.target.value)}
                               placeholder={"Search Personnel"} onFocus={() => setSearchFocused(true)}
                               onBlur={() => setSearchFocused(false)} className="input" onKeyDown={keyPress} type="text"
                               ref={searchRef}/>
                        <div className="clear" onClick={() => {
                            setSearch("");
                            setSearchInput("");
                        }}></div>
                    </div>
                    <Popup closeOnDocumentClick ref={sortRef} position={"bottom left"} trigger={<div>
                        <SortButton
                            buttonState=""
                            sortValue={selectedSort ? selectedSort.title : ""}
                            addClass="navAction"
                        />
                    </div>}>
                        <div className={"pullDownMenu"}>
                            {
                                _.map(SORTS, function (s, sIndex) {
                                    return (
                                        <div key={sIndex}
                                             className={`menuCell ${s.key === sort ? `selected sortDirection-${sortDirection}` : ''}`}
                                             onClick={onSortClick.bind(this, s.key)}>
                                            <div className="satisfactionMenuItemTitle">{s.title}</div>
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </Popup>
                </div>

                <div className="satisfactionComponentsAlignView">
                    <div style={{paddingRight: '10px'}}>
                        <button
                            className={filtersCount > 0 ? "button dismiss clearFilterActive" : "button dismiss clearFilterInActive disabled"}
                            onClick={handleClearFilters}
                            disabled={filtersCount === 0}>Clear Filters
                        </button>
                    </div>
                    <Modal
                        button={(
                            <FilterButton
                                addClass={filtersCount > 0 ? "button confirm medium" : "button dismiss medium"}
                                title={filtersCount > 0 ? `Filters(${filtersCount})` : "Filters"}
                            />
                        )}
                        content={(<AddEditSatisfactionFilters onSaveFilters={handleSaveFilters}
                                                              onCancel={handleFilterOptionsClose}
                                                              onResetFilter={() => {
                                                                  clearAllFilters(true);
                                                              }}
                                                              shifts={shifts}
                                                              filters={filters}
                                                              lastDaysInput={lastDays}
                                                              startDate={startDateInput}
                                                              endDate={endDateInput}
                                                              shiftId={shiftId}
                        />)}
                        dialogClassName="modalDialog satisfactionFilters"
                        title="Filters"
                        size="small"
                        handleClose={handleFilterOptionsClose}
                        handleOpen={handleFilterOptionsOpen}
                        open={filterOptions}
                    />
                </div>
            </div>
            <div className="scrollView">
                <div className="tableView">
                    <div className="tableSection">
                        {
                            personnel.map(function (p) {
                                return (
                                    <SatisfactionPersonnelCell
                                        personnelName={p.name}
                                        key={p.internalPersonnelId}
                                        onCellClick={(e) => {
                                            // If they click anything but a clickable attribute on the row, then ignore it
                                            if (!e.target.classList.contains("personnelCellClickable")) {
                                                return;
                                            }

                                            props.history.push({
                                                pathname: "/satisfaction-personnel/" + p.internalPersonnelId,
                                                search: "?" + new URLSearchParams({
                                                    startDate: startDate,
                                                    endDate: endDate
                                                })
                                            })
                                        }}
                                        personnelRole={p.personnelType}
                                        internalPersonnelId={p.internalPersonnelId}
                                        personnelID={p.personnelId}
                                        onEmailUpdate={() => {
                                            loadPage(page);
                                        }}
                                        personnelEmail={p.email}
                                        surveysCount={p.numberResponses}
                                        positiveFeedbackCount={p.numberPositiveResponses}
                                        satisfactionScore={_.round((p.cpss * 100), 1) + '%'}
                                    />
                                )
                            })
                        }
                        {
                            hasMore ? (
                                <PaginationExpanderButton onClick={loadMore}/>
                            ) : null
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SatisfactionWithFilterIndex));
