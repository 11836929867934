import React from "react";
import {API} from "aws-amplify";
import {toast} from "react-toastify";
import _ from "lodash";

import { Button } from "@/components/Buttons";
import Modal from "@/common/Modal";
import {COMPARISON_TO_TEXT_MAP} from "@/common/RuleComparisonConstants";

import TestRuleCell from "./TestRuleCell";

function TestRule({ruleId, ruleVersion, encounterId, encounterRevisionId, schema, ...props}) {
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [results, setResults] = React.useState(false);

    React.useEffect(() => {
        if(open){
            testRule();
        }
    }, [open])

    const testRule = () => {
        setLoading(true);
        API.get('Core', '/api/v1/rule/' + ruleId + '/version/' + ruleVersion.id + '/match/' + encounterId + '/' + encounterRevisionId)
            .then(response => {
                    setResults(response);
                    setLoading(false);
                },
                error => {
                    toast("Could not test rule against encounter: " + error.response.data.message, {
                        position: toast.POSITION.TOP_CENTER,
                        type: toast.TYPE.ERROR
                    });
                    setLoading(false);
                }
            );
    }

    const handleClose = () => {
        setResults(null);
        setOpen(false);
    }

    let content = null;
    if(open) {
        content = (
            <div className="modalDialog testRuleView">
                <div className="titleBar">
                    <div className="title">{loading ? "Testing Rule..." : "Test Rule Results"}</div>
                    <div className="separator"/>
                </div>

                <div className="content">
                    <div className="tableView testedRules">
                        <div className="tableSection">
                            {
                                _.map(ruleVersion.qualifiers, function(rvq){
                                    let qualifierResult = results ? _.find(results.qualifierResults, function(qr){ return qr.qualifier.id == rvq.id;}) : null;
                                    let status = "loading";
                                    let val = "";
                                    if(qualifierResult){
                                        status = qualifierResult.pass ? "success" : "fail";
                                        val = qualifierResult.value;
                                        if(qualifierResult.errorMsg){
                                            val = qualifierResult.errorMsg;
                                        }

                                        if(qualifierResult.fieldType == "SET" && val){
                                            val = _.join(val, ", ");
                                        }
                                    }
                                    let schemaField = _.find(schema, function(s){ return s.key == rvq.standardFieldName;});
                                    console.log('schemaField: ',schemaField);
                                    if(!schemaField){
                                        return null;
                                    }
                                    return (
                                        <TestRuleCell
                                           testStatus={status}
                                           filterSchema={schemaField.name}
                                           filterSchemaType={schemaField.type}
                                           filterOperator={COMPARISON_TO_TEXT_MAP[rvq.comparison]}
                                           filterValue={rvq.value}
                                           filterActualValue={val}
                                        />
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>

                <div className="actionBar">

                    <div className="actions right">
                        <Button
                            onClick={handleClose}
                            title="Done"
                            icon="none"
                            size="medium"
                            style="primary"
                        />
                    </div>

                    <div className="separator"/>
                </div>
            </div>
        )
    }

    return (<Modal
        button={props.children}
        content={content}
        title={"Test Rule"}
        size="medium"
        titleBar="hidden"
        handleClose={handleClose}
        handleOpen={() => setOpen(true)}
        open={open}
    />)
}

export default TestRule
