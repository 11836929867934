import React from 'react';
import _ from "lodash";
import {useTranslation} from 'react-i18next';

const fieldsetScaleStyles = {
    border: 'none',
    padding: '0',
    margin: '0',
    width: '100%',
    boxSizing: 'border-box'
};

const radioInputStyles = {
    marginRight: '8px',
    transform: 'scale(1.0)',
}

const radioContainerStyles = {
    marginBottom: '4px',
    display: 'flex',
    alignItems: 'center',
}

function SurveyQuestionChoice({question, answer, onAnswer, language}) {
    const [languageSetup, setLanguageSetup] = React.useState(false);

    React.useEffect(() => {
        console.log('setup')
        setupTranslations(question);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setupTranslations = (_question) => {
        let languagesTranslations = null;

        try {
            languagesTranslations = JSON.parse(question.languagesTranslations)[language];
        } catch (e) {
            console.warn('Problem to parse choice question languages translations');
        }

        _question.prompt = language && language !== 'en' && languagesTranslations && languagesTranslations.prompt ? languagesTranslations.prompt : _question.prompt;

        _question.options.forEach((option) => {
            try {
                let languagesTranslations;
                languagesTranslations = JSON.parse(option.languagesTranslations)[language];
                option.option = language && language !== 'en' && languagesTranslations && languagesTranslations.option ? languagesTranslations.option : option.option;
            } catch (e) {
                console.warn('Problem to parse choice question option languages translations');
            }
            return option
        })

        setLanguageSetup(true)
    }

    const onSelect = (option) => {
        onAnswer({selectedOption: option})
    }

    if (languageSetup) {
        return (
            <div className="questionCell answerChoice">
                <fieldset style={fieldsetScaleStyles}>
                    <legend className="surveyQuestion">{question.prompt}</legend>
                    <div className="surveyAnswers">
                        {
                            _.map(_.sortBy(question.options, function (oo) {
                                return oo.optionOrder;
                            }), function (o) {
                                return (
                                    <div style={{
                                        width: 'fit-content',
                                        marginLeft: '0px',
                                    }} onClick={onSelect.bind(this, o)}
                                         className={`surveyAnswer ${(answer && answer.selectedOption && answer.selectedOption.id === o.id) ? 'selected' : ''}`}>
                                        <div className="answerTitle" role="button">{o.option}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </fieldset>
            </div>
        );
    }
    return null;
}

function SurveyQuestionScale({question, answer, onAnswer, language}) {
    const [languageSetup, setLanguageSetup] = React.useState(false);

    React.useEffect(() => {
        setupTranslations(question);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setupTranslations = (_question) => {
        let languagesTranslations = null;

        try {
            languagesTranslations = JSON.parse(_question.languagesTranslations)[language];
        } catch (e) {
            console.warn('Problem to parse scale question languages translations');
        }

        _question.prompt = language && language !== 'en' && languagesTranslations && languagesTranslations.prompt ? languagesTranslations.prompt : _question.prompt;
        _question.lowDescription = language && language !== 'en' && languagesTranslations && languagesTranslations.lowDescription ? languagesTranslations.lowDescription : _question.lowDescription;
        _question.highDescription = language && language !== 'en' && languagesTranslations && languagesTranslations.highDescription ? languagesTranslations.highDescription : _question.highDescription;

        setLanguageSetup(true);
    }

    const onClick = (number) => {
        onAnswer({rangeSelection: number});
    }

    if (languageSetup) {
        return (
            <div className="questionCell answerChoice">
                <fieldset style={fieldsetScaleStyles}>
                    <legend className="surveyQuestion">{question.prompt}</legend>
                    <div className="fontStyleAnswer answersLabels" style={{
                        paddingTop: '10px', paddingBottom: '10px'
                    }}>
                        <div className="label min">{question.lowDescription ? question.lowDescription : ''}</div>
                    </div>
                    <div className="fontStyleAnswer surveyAnswers">
                        {
                            _.map(_.range(question.low, question.high + 1), function (i) {
                                return (
                                    <div key={i} style={radioContainerStyles}>
                                        <label>
                                            <input
                                                type="radio"
                                                name="rating"
                                                value={i}
                                                onChange={() => onClick(i)}
                                                style={radioInputStyles}
                                                checked={answer && answer.rangeSelection === i}
                                            />
                                            {i}
                                        </label>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="fontStyleAnswer answersLabels" style={{
                        paddingTop: '10px', paddingBottom: '10px'
                    }}>
                        <div className="label max">{question.highDescription ? question.highDescription : ''}</div>
                    </div>
                </fieldset>
            </div>
        );
    }
    return null;
}

function SurveyQuestionComment({question, onAnswer, language}) {
    const {t} = useTranslation();
    const responseRef = React.createRef();
    const [length, setLength] = React.useState(0);
    const [languageSetup, setLanguageSetup] = React.useState(false);

    React.useEffect(() => {
        setupTranslations(question);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setupTranslations = (_question) => {
        let languagesTranslations = null;

        try {
            languagesTranslations = JSON.parse(_question.languagesTranslations)[language];
        } catch (e) {
            console.warn('Problem to parse scale question languages translations');
        }

        _question.prompt = language && language !== 'en' && languagesTranslations && languagesTranslations.prompt ? languagesTranslations.prompt : _question.prompt;
        _question.lowDescription = language && language !== 'en' && languagesTranslations && languagesTranslations.lowDescription ? languagesTranslations.lowDescription : _question.lowDescription;
        _question.highDescription = language && language !== 'en' && languagesTranslations && languagesTranslations.highDescription ? languagesTranslations.highDescription : _question.highDescription;

        setLanguageSetup(true);
    }

    const onChange = () => {
        onAnswer({comment: responseRef.current.value});
        if (responseRef && responseRef.current && responseRef.current.value) {
            setLength(responseRef.current.value.length);
        }
    }

    const MAX_CHARACTERS = 255;

    if (languageSetup) {
        return (
            <div
                className="questionCell answerComment"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    marginBottom: '16px',
                }}
            >
                <div
                    className="surveyQuestion"
                    style={{
                        fontWeight: 'bold',
                        marginBottom: '8px',
                    }}
                >
                    {question.prompt}
                </div>

                <div
                    className="surveyAnswers"
                    style={{
                        width: '100%',
                    }}
                >
                    <div
                        className="surveyAnswer inputCell"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                        }}
                    >
                <textarea
                    maxLength={MAX_CHARACTERS}
                    aria-label={question?.prompt}
                    style={{
                        width: '100%',
                        height: '10vh',
                        maxHeight: '100px',
                        minHeight: '50px',
                        background: 'transparent',
                        resize: 'none',
                        border: '1px solid #ccc',
                        outline: 'none',
                        padding: '8px',
                        boxSizing: 'border-box',
                    }}
                    ref={responseRef}
                    onChange={onChange}
                ></textarea>
                    </div>
                </div>
                <div
                    className="answersLabels"
                    style={{
                        textAlign: 'right',
                        marginTop: '4px',
                        fontSize: '12px',
                    }}
                >
                    <div className="label max">
                        {`${length || 0} / 255 ${t('Characters Remaining')}`}
                    </div>
                </div>
            </div>
        );
    }
    return null;
}

export {SurveyQuestionChoice, SurveyQuestionScale, SurveyQuestionComment};